import * as React from 'react';
import Seo from '@atoms/seo';

const NotFoundPage = () => (
  <>
    <Seo meta_title="404: Not found" />
    <div className="p-8 bg-white text-center h-96 max-h-screen flex items-center justify-center">
      <h1>Page not found</h1>
    </div>
  </>
);

export default NotFoundPage;
